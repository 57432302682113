.nav-link.active.underline:after {
    content: "";
    float: left;
    background: #000;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    margin-top: 2px;

}

.nav-link.active {
    font-weight: bold;
}