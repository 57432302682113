.autocomplete-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    border-radius: 4px;
}

.autocomplete-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #000;
}

.autocomplete-container .filled {
    transform: translate(0, 12px) scale(0.8);
    color: #000;
}

.autocomplete-container .btn-close {
    box-shadow: none !important;
}

.autocomplete-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #9B9B9B;
    font-size: 16px;
    line-height: 1;
    left: 16px;
}

.autocomplete-container input {
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.autocomplete-container:focus-within {
    box-shadow: 0 0 0 2px #dfdfdf;
}

.rbt-input-multi {
    background-color: #F7F7F7 !important;
    border: none !important;
    padding: 14px 16px 14px 16px;
}

.rbt-input-multi input {
    margin-top: 5px !important;
    margin-left: 10px !important;
}

.rbt-input-wrapper {
    padding: 24px 0px 10px 0px;
}

.rbt-token.stop {
    color: blue !important;
}

.rbt-token.waypoint {
    color: magenta !important;
    background-color: #ffe7fe !important;
}