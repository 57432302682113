
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #F7F7F7;
  border-radius: 4px;
}

.no-right-radius .input-container {
  
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #000;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #9B9B9B;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container input {
  height: 64px;
  border-radius: 4px;
  border: none;
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container.invalid {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #dc3545;
}

.input-container.invalid input:focus  {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #dc3545;
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #dfdfdf;
}

.input-container textarea {
  border-radius: 4px;
  border: none;
  padding: 30px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  min-height: 90px;
}

.input-container textarea:focus {
  box-shadow: 0 0 0 2px #dfdfdf;
}

.input-container.invalid textarea:focus  {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #dc3545;
}

.input-container button {
  height: 64px;
}

