
.offcanvas-size-xxl {
    --bs-offcanvas-width: min(95vw, 90vw) !important;
}
.offcanvas-size-xl {
    --bs-offcanvas-width: min(80vw, 1200px) !important;
}

.offcanvas-vertical-size-xl {
    --bs-offcanvas-height: min(95vh, 650vh) !important;
}

.offcanvas-size-lg {
    --bs-offcanvas-width: min(95vw, 600px) !important;
}
.offcanvas-size-md { /* add Responsivenes to default offcanvas */
    --bs-offcanvas-width: min(95vw, 400px) !important;
}
.offcanvas-size-sm {
    --bs-offcanvas-width: min(95vw, 250px) !important;
}

.offcanvas-backdrop {
    z-index: 1060 !important;
}

.offcanvas {
    z-index: 1065 !important;
}

.offcanvas-size-fullscreen-xl {
    --bs-offcanvas-width: min(98vw, 1200px) !important;
}
.offcanvas-size-fullscreen-lg {
    --bs-offcanvas-width: min(98vw, 992px) !important;
}
.offcanvas-size-fullscreen-md {
    --bs-offcanvas-width: min(98vw, 768px) !important;
}