.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
    border-radius: 50% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    background: #0F05 !important;
    border: 2px solid black !important;
  }

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked {
  background: #0F05 !important;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  background-color: transparent !important;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
  background-color: transparent !important;
  border-radius: 50% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  border: 2px solid black !important;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input {
  border-radius: 50% !important;
}

input[name="acceptance"]:checked::before {
  opacity: 0 !important;
}

input[name="acceptance"]:checked {
  background: black !important;
}
