.route-map-page-map-height {
  height: 35vh;
}

@media (min-width: 768px) {
    .route-map-page-map-height {
        height: 100vh;
    }
}

.route-map-page-list-height {
    height: calc(100vh - 54px - 35vh - 64px - 54px - 40px);
  }
  
  @media (min-width: 768px) {
      .route-map-page-list-height {
          height: calc(100vh - 60px - 64px - 54px - 32px);;
      }
  }