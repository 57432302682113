.nav-pills .nav-link.active {
  background-color: black !important;
  color: white !important;
}

a.nav-link {
  color: black !important;
}

li.nav-link {
  color: black !important;
}