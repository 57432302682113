@media (min-width: 576px) {
    .h-sm-100 {
        height: 100% !important;
    }
}

@media (max-width: 576px) {
    .bg-sm-down-white {
        background-color: rgba(var(--bs-white-rgb),1)!important
    }
    .shadow-sm-down-sm {
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    }
}